<template>
  <div
    class="forgot-password-form forgot-password-child card d-flex flex-column"
  >
    <h1>{{ $t("forgotPassword.title") }}</h1>
    <div class="subtitle-1 text-center">
      {{ $t("forgotPassword.subtitle") }}
    </div>
    <v-form
      ref="form"
      v-model="formValid"
      @submit.prevent="handleSubmit"
      lazy-validation
    >
      <v-row>
        <v-col cols="12" class="pt-0">
          <!-- Email input -->
          <v-text-field
            class="reset-email"
            v-model="formData.email"
            :label="$t('common.email')"
            :rules="[$rules.required, $rules.email]"
            type="email"
            prepend-inner-icon="mdi-email"
            filled
          />
        </v-col>
      </v-row>
      <api-response :response="formResponse" class="mt-5" />
      <v-spacer />
      <!-- Send button -->
      <v-btn
        color="primary"
        type="submit"
        :disabled="!formValid"
        :loading="$store.getters['auth/loading'].forgotPassword"
        block
      >
        {{ $t("common.send") }}
      </v-btn>
      <!-- Login link -->
      <small class="d-block text-center mt-3">
        <router-link :to="{ name: 'auth.login' }">
          {{ $t("login.returnToLogin") }}
        </router-link>
      </small>
    </v-form>
  </div>
</template>

<script>
export default {
  data: () => ({
    formData: {},
    formValid: false,
    formResponse: null,
  }),
  methods: {
    handleSubmit() {
      this.$store
        .dispatch("auth/forgotPassword", this.formData)
        .then(() => {
          this.$emit("success");
        })
        .catch((resp) => {
          this.formResponse = resp.data;
        });
    },
  },
};
</script>

<style lang="scss">
.forgot-password-form {
  text-align: center;
  h1,
  .reset-email {
    margin-bottom: 10px;
  }
  .subtitle-1 {
    margin-bottom: 62px;
  }
  .v-btn {
    flex-grow: 0;
  }
}
</style>

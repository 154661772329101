<template>
  <div
    class="forgot-password-success forgot-password-child card d-flex flex-column"
  >
    <h1>{{ $t("forgotPassword.success.heading") }}</h1>
    <div class="text-center subtitle-wrapper">
      <p class="subtitle-1">
        {{ $t("forgotPassword.success.text1") }}
      </p>
      <p class="subtitle">
        {{ $t("forgotPassword.success.text2") }}
      </p>
    </div>
    <v-spacer />
    <!-- Login link -->
    <router-link class="return-login" :to="{ name: 'auth.login' }">
      {{ $t("login.returnToLogin") }}
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.forgot-password-success {
  text-align: center;
  h1 {
    margin-bottom: 85px;
  }
}
</style>

<template>
  <div class="page">
    <ForgotPasswordForm @success="isForm = false" v-if="isForm" />
    <ForgotPasswordSuccess v-else />
  </div>
</template>

<script>
import ForgotPasswordForm from "@/components/auth/forgot-password/ForgotPasswordForm";
import ForgotPasswordSuccess from "@/components/auth/forgot-password/ForgotPasswordSuccess";
export default {
  components: { ForgotPasswordSuccess, ForgotPasswordForm },
  data: () => ({
    isForm: true,
  }),
};
</script>
